import { Link } from '@remix-run/react'
import { LucideUserCircle } from 'lucide-react'
import { useMemo } from 'react'
import { aUser, useAtomValue } from '~/atoms'
import { Avatar, Button } from '~/components/ui'
import { cn } from '~/utils'

export default function AppUserDropdown() {
  const user = useAtomValue(aUser)

  const fallback = useMemo(() => {
    if (!user) {
      return null
    }
    return [user!.firstName.slice(0, 1), user!.lastName.slice(0, 1)].join('')
  }, [user])

  return (
    <Button asChild variant="map" size="map" className="shadow-none">
      <Link
        viewTransition
        className={cn(user && user.meta?.photo && 'p-0')}
        to={user ? '/admin' : '/login'}>
        {user ? (
          <Avatar className="h-10 w-10 rounded-md">
            {user!.meta?.photo && (
              <Avatar.Image
                src={user!.meta.photo.thumbUrl}
                alt={`${user!.firstName} ${user!.lastName}`}
              />
            )}
            <Avatar.Fallback className="rounded-none bg-transparent">
              {fallback}
            </Avatar.Fallback>
          </Avatar>
        ) : (
          <LucideUserCircle className="h-5 w-5" />
        )}
      </Link>
    </Button>
  )
}
