import centerOfMass from '@turf/center-of-mass'
import { FeatureCollection } from 'geojson'
import { EventData } from 'mapbox-gl'
import { useEffect, useMemo, useState } from 'react'
import {
  Layer,
  LayerProps,
  MapboxGeoJSONFeature,
  MapMouseEvent,
  Popup,
  Source,
  useMap,
} from 'react-map-gl'
import { useGeographiesQuery } from '~/api'
import { aVisibleGeographies, useAtomValue } from '~/atoms'
import { MapView, useCurrentMapView } from '~/hooks'

export default function MapGeographiesLayer() {
  const mapRef = useMap()
  const currentMapView = useCurrentMapView()
  const visibleGeographies = useAtomValue(aVisibleGeographies)
  const [selectedGeography, setSelectedGeography] = useState<{
    id: string
    type: string
    name: string
    latitude: number
    longitude: number
  } | null>(null)

  const { geographies } = useGeographiesQuery({
    key: ['map-geographies', ...visibleGeographies],
    find: { type: 'submarket' },
    enabled: visibleGeographies.length > 0,
  })

  const geojson = useMemo(() => {
    return {
      type: 'FeatureCollection',
      features: geographies.map((geo) => {
        const center = centerOfMass(geo.feature.geometry)
        return {
          ...geo.feature,
          id: geo._id,
          properties: {
            _id: geo._id,
            type: geo.type,
            name: geo.name,
            latitude: center.geometry.coordinates[1],
            longitude: center.geometry.coordinates[0],
          },
        }
      }),
    } as FeatureCollection
  }, [geographies])

  const layerStyle: LayerProps = {
    type: 'line',
    source: 'geographies',
    beforeId: mapRef.current?.getLayer('property-clusters')
      ? 'property-clusters'
      : undefined,
    paint: {
      'line-color': '#3bb2d0',
      // 'line-opacity': 0.75,

      'line-width': 2,
    },
  }

  const layerStyleFill: LayerProps = {
    id: 'geographies-fill',
    source: 'geographies',
    interactive: true,
    beforeId: mapRef.current?.getLayer('property-clusters')
      ? 'property-clusters'
      : undefined,
    type: 'fill',
    paint: {
      'fill-color': '#3bb2d0',
      'fill-opacity': 0.05,
    },
  }

  useEffect(() => {
    const onMouseMove = (
      e: MapMouseEvent & {
        features?: MapboxGeoJSONFeature[] | undefined
      } & EventData
    ) => {
      if ((e.features || []).length > 0) {
        const feature = e.features![0]
        setSelectedGeography({
          ...feature.properties,
          latitude: feature.properties!.latitude,
          longitude: feature.properties!.longitude,
        } as any)
      }
    }
    const onMouseOut = () => {
      setSelectedGeography(null)
    }

    if (mapRef.current) {
      const map = mapRef.current!.getMap()
      map.on('mousemove', 'geographies-fill', onMouseMove)
      map.on('mouseleave', 'geographies-fill', onMouseOut)
    }
    return () => {
      if (mapRef.current) {
        const map = mapRef.current!.getMap()
        map.off('mousemove', onMouseMove)
        map.off('mouseleave', 'geographies-fill', onMouseOut)
      }
    }
  }, [mapRef.current])

  if (geographies.length === 0 || currentMapView !== MapView.Map) {
    return null
  }

  return (
    <>
      <Source
        id="geographies"
        type="geojson"
        promoteId="properties._id"
        data={geojson}>
        <Layer {...layerStyle} />
        <Layer {...layerStyleFill} />
      </Source>
      {selectedGeography && (
        <Popup
          // maxWidth="400px"
          key={selectedGeography.id}
          latitude={selectedGeography.latitude}
          longitude={selectedGeography.longitude}
          closeButton={false}
          onClose={() => setSelectedGeography(null)}
          className="disable-pointer-events z-30">
          {selectedGeography.name}
        </Popup>
      )}
    </>
  )
}
