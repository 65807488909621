import { useMemo } from 'react'
import { aOrganization, useAtomValue } from '~/atoms'
import { Button, Checkbox, Label } from '~/components/ui'
import { STATUSES } from '~/utils'

export default function MapListingStatusFilter({
  statuses,
  updateFilter,
}: {
  statuses: string[]
  updateFilter: (key: string, value: any) => void
}) {
  const organization = useAtomValue(aOrganization)
  const setStatuses = (status: string, value: boolean) => {
    if (value) {
      updateFilter('statuses', [...statuses, status])
    } else if (statuses.length > 1) {
      updateFilter(
        'statuses',
        statuses.filter((p) => p !== status)
      )
    }
  }

  const statusOptions = useMemo(() => {
    if (organization!.options.appraisersOnly) {
      return ['leased', 'sold']
    }
    return Object.keys(STATUSES)
  }, [organization])

  return (
    <div className="space-y-2">
      <p className="text-sm">
        Status
        {(statuses.length > 1 ||
          (statuses.length > 0 && statuses[0] !== 'active')) && (
          <Button
            variant="link"
            size="none"
            className="ml-2"
            onClick={() => updateFilter('statuses', ['active'])}>
            (reset)
          </Button>
        )}
      </p>
      <div className="grid grid-cols-2 gap-4">
        {statusOptions.map((key) => {
          if (key === 'draft' || key === 'deleted') {
            return null
          }
          return (
            <Label
              key={key}
              htmlFor={`status-${key}`}
              className="flex items-center gap-2 text-sm font-normal">
              <Checkbox
                id={`status-${key}`}
                checked={statuses.includes(key)}
                onCheckedChange={(value) => {
                  setStatuses(key, value.valueOf() as boolean)
                }}
              />
              <span>{STATUSES[key]}</span>
            </Label>
          )
        })}
      </div>
    </div>
  )
}
