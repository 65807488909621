import { useState } from 'react'
import { aCheckedListings, useAtom } from '~/atoms'
import { Checkbox } from '~/components/ui'
import { type Listing } from '~/models'

export default function CheckAllListings({
  listings,
  currentMapCount,
}: {
  listings: Listing[]
  currentMapCount: number | null
}) {
  const [checkedListings, setCheckedListings] = useAtom(aCheckedListings)
  const [isChecked, setIsChecked] = useState(false)

  return (
    <div className="hidden h-10 items-center gap-2 rounded-md bg-background pl-2 pr-3 text-sm font-medium shadow-md xl:inline-flex">
      <Checkbox
        id="check-all"
        className="border-foreground-muted ring-offset-transparenthover:ring-offset-1 block h-6 w-6 border bg-background text-accent-foreground shadow-lg ring-primary hover:border-background hover:ring-1 data-[state=checked]:border-primary"
        checked={isChecked}
        onCheckedChange={(e) => {
          setIsChecked(!isChecked)
          const allIds = listings.slice(0, 60).map((listing) => listing._id)
          if (isChecked) {
            setCheckedListings([])
          } else {
            setCheckedListings([...new Set([...checkedListings, ...allIds])])
          }
        }}
      />
      <label htmlFor="check-all" className="cursor-pointer font-medium">
        {isChecked
          ? 'Select None'
          : `Select All ${currentMapCount && currentMapCount > 60 ? '(up to 60)' : ''}`}
      </label>
    </div>
  )
}
