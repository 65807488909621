import { isEqual, omit } from 'lodash-es'
import { LucideSlidersHorizontal } from 'lucide-react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAdminCompaniesQuery, useAdminUsersQuery } from '~/api'
import {
  aDefaultMapFilters,
  aMapFilters,
  aOrganization,
  aUser,
  useAtom,
  useAtomValue,
} from '~/atoms'
import { ControlTooltip, PulseDot } from '~/components'
import { Button, Separator, Sheet } from '~/components/ui'
import { useIsEmbed, useIsSafari } from '~/hooks'
import { UserRole } from '~/models'
import {
  LocationFilters,
  MapBrokersFilter,
  MapCompaniesFilter,
  MapDateFilter,
  MapListingStatusFilter,
  MapMinMaxFilter,
  MapPropertySubtypesFilter,
  MapPropertyTypesFilter,
  MapSearchFilter,
  MapSort,
  MapTypeFilter,
} from './filters'

function getObjectDiff(
  obj1: { [key: string]: any },
  obj2: { [key: string]: any }
) {
  const diff = Object.keys(obj1).reduce((result, key) => {
    if (!obj2.hasOwnProperty(key)) {
      result.push(key)
    } else if (isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key)
      result.splice(resultKeyIndex, 1)
    }
    return result
  }, Object.keys(obj2))

  return diff
}

export default function MapFilterSheet() {
  const organization = useAtomValue(aOrganization)
  const user = useAtomValue(aUser)
  const defaultMapFilters = useAtomValue(aDefaultMapFilters)
  const [mapFilters, setMapFilters] = useAtom(aMapFilters)
  const [filters, setFilters] = useState(mapFilters)
  const [isOpen, setIsOpen] = useState(false)
  const isEmbed = useIsEmbed()
  const isSafari = useIsSafari()

  const { companies } = useAdminCompaniesQuery(['active-companies'], {
    status: 'active',
  })
  const { users: brokers } = useAdminUsersQuery(['active-brokers'], {
    status: 'active',
    role: {
      $in: [UserRole.broker, UserRole.managingBroker],
    },
  })

  useEffect(() => {
    if (!isOpen) {
      setFilters(mapFilters)
    }
  }, [isOpen, mapFilters, setFilters])

  const updateFilter = useCallback(
    (key: string, value: any) => {
      const newFilters: { [key: string]: any } = { ...filters }
      if (typeof value === 'undefined') {
        delete newFilters[key]
      } else {
        newFilters[key] = value
      }
      // Clearing subtypes elsehwere caused issues
      if (key === 'propertyTypes' && (!value || value.length === 0)) {
        delete newFilters['propertySubtypes']
      }
      setFilters(newFilters)
    },
    [filters]
  )

  const updateMapFilters = () => {
    if ((filters.types || []).includes('sale') === false) {
      filters.prices = []
      filters.caps = []
    }
    if ((filters.types || []).includes('lease') === false) {
      filters.rates = []
      filters.sf = []
    }
    setMapFilters(filters)
    setIsOpen(false)
  }

  const resetMapFilters = () => {
    const filters = {
      lat: mapFilters.lat,
      lng: mapFilters.lng,
      z: mapFilters.z,
    }
    setMapFilters(filters)
    setTimeout(() => setFilters({ ...filters, ...defaultMapFilters }), 200)
    setIsOpen(false)
  }

  const different = useMemo(() => {
    return (
      getObjectDiff(
        omit(mapFilters, ['lat', 'lng', 'z']),
        omit(defaultMapFilters, ['lat', 'lng', 'z'])
      ).length > 0
    )
  }, [mapFilters])

  return (
    <Sheet
      modal={false}
      open={isOpen}
      onOpenChange={(open) => {
        setIsOpen(open)
        if (!open) {
          setFilters(mapFilters)
        }
      }}>
      <ControlTooltip text="Filter and sort listings" side="bottom">
        <Sheet.Trigger asChild>
          <Button variant="map" size="map" className="relative shadow-md">
            <LucideSlidersHorizontal className="h-5 w-5" />
            {different && !isEmbed && (
              <PulseDot className="absolute -right-0.5 -top-0.5" />
            )}
          </Button>
        </Sheet.Trigger>
      </ControlTooltip>
      <Sheet.Content className="flex w-full flex-col" side="right">
        <Sheet.Header>
          <Sheet.Title>
            Filter Listings{' '}
            <Button
              variant="link"
              size="none"
              className="p-0"
              onClick={() => {
                if (isSafari) {
                  resetMapFilters()
                }
                resetMapFilters()
              }}>
              (reset all)
            </Button>
          </Sheet.Title>
          {/* <Sheet.Description></Sheet.Description> */}
        </Sheet.Header>
        <div className="-mx-6 flex-1 overflow-auto px-6">
          <form
            onSubmit={(e) => {
              e.preventDefault()
              if (isSafari) {
                updateMapFilters()
              }
              updateMapFilters()
            }}>
            <div className="space-y-4 pb-2">
              <MapSort sort={filters.sort} updateFilter={updateFilter} />

              <MapSearchFilter
                search={filters.search}
                updateFilter={updateFilter}
              />

              <MapPropertyTypesFilter
                propertyTypes={filters.propertyTypes || []}
                updateFilter={updateFilter}
              />

              <MapPropertySubtypesFilter
                propertySubtypes={filters.propertySubtypes || []}
                propertyTypes={filters.propertyTypes || []}
                updateFilter={updateFilter}
              />
              {user && (
                <MapListingStatusFilter
                  statuses={filters.statuses || []}
                  updateFilter={updateFilter}
                />
              )}
              <MapTypeFilter
                types={filters.types || []}
                updateFilter={updateFilter}
              />

              {(filters.types || []).includes('sale') && (
                <>
                  <Separator />
                  <p className="font-semibold">Sale</p>

                  <MapMinMaxFilter
                    className="!mt-2"
                    name="prices"
                    label="Sale Price"
                    start="$"
                    filterValues={filters.prices || []}
                    updateFilter={updateFilter}
                  />

                  <MapMinMaxFilter
                    name="caps"
                    label="Cap Rate"
                    end="%"
                    filterValues={filters.caps || []}
                    updateFilter={updateFilter}
                  />
                </>
              )}

              {(filters.types || []).includes('lease') && (
                <>
                  <Separator />
                  <p className="font-semibold">Lease</p>

                  <MapMinMaxFilter
                    className="!mt-2"
                    name="rates"
                    label="Lease Rate"
                    start="$"
                    end="SF/yr"
                    filterValues={filters.rates || []}
                    updateFilter={updateFilter}
                  />
                  <MapMinMaxFilter
                    name="sf"
                    label="Available SF"
                    end="SF"
                    filterValues={filters.sf || []}
                    updateFilter={updateFilter}
                  />
                </>
              )}

              <Separator />
              <p className="font-semibold">Property</p>

              <MapMinMaxFilter
                name="bsf"
                label="Building SF"
                end="SF"
                filterValues={filters.bsf || []}
                updateFilter={updateFilter}
              />
              <MapMinMaxFilter
                name="acres"
                label="Acres"
                end="ac"
                filterValues={filters.acres || []}
                updateFilter={updateFilter}
              />

              <Separator />

              {organization!.options.singleCompany !== true && (
                <>
                  <LocationFilters
                    filters={filters}
                    updateFilter={updateFilter}
                  />
                  <Separator />
                </>
              )}

              <p className="font-semibold">Dates</p>
              {organization!.options.appraisersOnly !== true && (
                <MapDateFilter
                  name="created"
                  label="Listed After"
                  filterValue={filters.created}
                  updateFilter={updateFilter}
                />
              )}

              <MapDateFilter
                className="!mb-0"
                name="transacted"
                label="Transacted After"
                filterValue={filters.transacted}
                updateFilter={updateFilter}
              />
              <p className="!mt-2 text-sm italic">
                Sold and Lease Statuses only
              </p>

              {isEmbed === false &&
                organization?.options.singleCompany !== true && (
                  <>
                    <Separator />
                    <p className="font-semibold">Members</p>
                    <MapCompaniesFilter
                      companies={companies}
                      filterCompanies={filters.companies}
                      updateFilter={updateFilter}
                    />
                    <MapBrokersFilter
                      brokers={brokers}
                      filterBrokers={filters.brokers}
                      updateFilter={updateFilter}
                    />
                  </>
                )}
              {organization!.options.singleCompany === true && (
                <>
                  <Separator />
                  <p className="font-semibold">Members</p>
                  <MapBrokersFilter
                    brokers={brokers}
                    filterBrokers={filters.brokers}
                    updateFilter={updateFilter}
                  />
                </>
              )}
            </div>
            <input className="hidden" type="submit" />
          </form>
        </div>
        <div className="flex gap-4 border-t pt-6">
          <Button
            onClick={() => {
              if (isSafari) {
                updateMapFilters()
              }
              updateMapFilters()
            }}>
            Update
          </Button>
          <Button variant="secondary" onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
        </div>
      </Sheet.Content>
    </Sheet>
  )
}
