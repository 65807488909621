import { useRouteLoaderData } from '@remix-run/react'
import { useMap } from 'react-map-gl'
import { GlobalSearch } from '~/components'
import { useMapCardSizes, useMapEaseTo } from '~/hooks'

export default function MapSearch() {
  const { originalUrl } = useRouteLoaderData('root') as { originalUrl: string }
  const mapRef = useMap()
  const mapCardSizes = useMapCardSizes()
  const mapEaseTo = useMapEaseTo(mapRef as any)

  if (originalUrl.includes('companies=')) {
    return null
  }

  return (
    <GlobalSearch
      className="w-42 hidden overflow-hidden rounded-md shadow-md lg:block"
      onPlaceSelect={({ lat, lng, zoom }) => {
        mapEaseTo({
          zoom,
          center: [lng, lat],
          padding: {
            left: mapCardSizes.list,
            top: 0,
            right: 0,
            bottom: 0,
          },
          duration: 500,
        })
      }}
    />
  )
}
